<template>
  <b-container>
    <b-row>
      <b-col class="text-center partners">
        <p class="h2 pb-3 pt-4">Nasi Partnerzy</p>

        <p class="h4">
          Obsługa prawna - Kancelaria Radcy Prawnego Sławomir Zarzycki<br />
        </p>

        <p class="h4">Ubezpieczenia Medyczne<br /></p>
        <a
          href="https://www.ubezpieczeniamedyczne.org/"
          alt="Ubezpieczenia medyczne"
          >www.ubezpieczeniamedyczne.org</a
        >

        <p class="h4">
          AMG Ubezpieczenia
        </p>

        <p class="h4">
          Ubezpieczenia OLIMP Plus Mateusz Dajosi
        </p>
        <a href="https://www.olimpplus.pl/" alt="Ubezpieczenia OLIMP Plus"
          >olimpplus.pl</a
        >

        <p class="h4">
          Kancelaria Podatkowa Dorota Tyszko-Wieprzowska
        </p>

        <p class="h4">Studio Optyczne ORANGE</p>

        <a href="http://studiooptyczne.eu/" alt="Studio Optyczne Orange Lublin"
          >studiooptyczne.eu</a
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Partners",
};
</script>

<style scoped>
p.h4 {
  padding-top: 20px;
}

a {
  text-decoration: none;
  font-size: 1rem;
}
</style>
